<template>
  <div class="no-padding">
    <div style="height: 100%;">

      <p style="font-weight: bold;">{{ $t('FORM.VISIBLE_FIELDS') }}</p>

      <ExpandableTable
        :children="shown_settings_data"
        :fields="shown_settings_fields"
        :indent_colors="['#eff4f9','#ffffff', '#ffffff', '#ffffff']"
        highlightable

        @content_link_clicked="content_link_clicked"
        @cell_value_changed="cell_checkbox_changed"
      />
    </div>
  </div>
</template>


<script>


import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';
import ExpandableTable from '@/view/components/tables/ExpandableTable.vue';

import axios from 'axios';
import dayjs from 'dayjs';


export default {

  text: 'FormPagePersonDetailsEditor',

  props: ['item','validate'],
  emits: ['change'],
  mixins: [ toasts ],

  components: {
    ExpandableTable
  },

  watch: {
    validate: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.force_validate = this.force_validate + 1;
      }
    },

    item: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_item = { ...this.item };

        if (!this.local_item.fields) {
          this.local_item.fields = {};
        }

        this.setup_initial_list();
      },
      immediate: true
    }
  },


  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId']),
    shown_settings_fields() {
      return [
        { name: 'text', text: this.$t('FORM.FIELD_NAME'), cols: 6 },
        { name: 'show', text: this.$t('FORM.SHOW'), cols: 2, type: 'checkbox' },
        { name: 'required', text: this.$t('FORM.REQUIRED'), cols: 2, type: 'checkbox' },
      ];
    },
  },

  async mounted() {

  },

  methods: {

    setup_from_local_item() {

      if (!this.local_item.fields) {
        console.error('local_item fields is undefined or null', this.local_item);
        return;
      }

      for (const r of this.shown_settings_data) {

        if (this.local_item.fields[r.name]) {
          r.show = this.local_item.fields[r.name].show;
          r.required = this.local_item.fields[r.name].required;
        }
      }

    },

    setup_initial_list() {
      this.shown_settings_data = [
        {
          name: 'firstname',
          text: this.$t('MEMBER.FIRSTNAME'),
          show: true,
          required: false
        },
        {
          name: 'lastname',
          text: this.$t('MEMBER.LASTNAME'),
          show: true,
          required: false
        },
        {
          name: 'email',
          text: this.$t('MEMBER.EMAIL'),
          show: true,
          required: false
        },
        {
          name: 'password',
          text: this.$t('MEMBER.PASSWORD'),
          show: false,
          required: false
        },
        {
          name: 'phone',
          text: this.$t('MEMBER.PHONE'),
          
          show: false,
          required: false
        },
        {
          name: 'landline',
          text: this.$t('MEMBER.LANDLINE'),
          
          show: false,
          required: false
        },
        {
          name: 'address',
          text: this.$t('MEMBER.ADDRESS'),
          show: false,
          required: false
        },
        {
          name: 'post',
          text: this.$t('MEMBER.POST'),
          
          show: false,
          required: false
        },
        {
          name: 'zipcode',
          text: this.$t('MEMBER.ZIPCODE'),
          
          show: false,
          required: false
        },
        {
          name: 'region_code',
          text: this.$t('MEMBER.REGION_CODE'),

          show: false,
          required: false
        },


        {
          name: 'commune_code',
          text: this.$t('MEMBER.COMMUNE_CODE'),

          show: false,
          required: false
        },


        {
          name: 'country',
          text: this.$t('MEMBER.COUNTRY'),

          show: false,
          required: false
        },
        {
          name: 'dob',
          text: this.$t('MEMBER.DOB'),

          show: false,
          required: false
        },
        {
          name: 'avd',
          text: this.$t('MEMBER.AVD'),

          show: false,
          required: false
        },
        {
          name: 'org_number',
          text: this.$t('MEMBER.ORG_NUMBER'),

          show: false,
          required: false
        },
        {
          name: 'cname',
          text: this.$t('MEMBER.CNAME'),

          show: false,
          required: false
        },

        {
          name: 'public_sex',
          text: this.$t('MEMBER.PUBLIC_SEX'),

          show: false,
          required: false
        },
        {
          name: 'invoice_firstname',
          text: this.$t('MEMBER.INVOICE_FIRSTNAME'),

          show: false,
          required: false
        },
        {
          name: 'invoice_lastname',
          text: this.$t('MEMBER.INVOICE_LASTNAME'),

          show: false,

          required: false
        },
        {
          name: 'invoice_email',
          text: this.$t('MEMBER.INVOICE_EMAIL'),

          show: false,

          required: false
        },
        {
          name: 'invoice_phone',
          text: this.$t('MEMBER.INVOICE_PHONE'),

          show: false,

          required: false
        },
        {
          name: 'invoice_address',
          text: this.$t('MEMBER.INVOICE_ADDRESS'),

          show: false,

          required: false
        },
        {
          name: 'invoice_zipcode',
          text: this.$t('MEMBER.INVOICE_ZIPCODE'),

          show: false,

          required: false
        },
        {
          name: 'invoice_post',
          text: this.$t('MEMBER.INVOICE_POST'),

          show: false,

          required: false
        },
        {
          name: 'invoice_country',
          text: this.$t('MEMBER.INVOICE_COUNTRY'),

          show: false,

          required: false
        }
      ]

      if (Object.keys(this.local_item.fields).length === 0) {
        for (const s of this.shown_settings_data) {
          this.local_item.fields[s.name] = { show: s.show, required: s.required };
        }
      }
    },

    async cell_checkbox_changed(value) {

      const node = this.find_node_with_row_number(value.row);

      if (node === null) {
        console.error('unable to find row', node);

        return;
      }

      const row = this.shown_settings_data.find(r => r.name === value.item.name);

      if (value.name === 'required') {
        row.required = value.value;
      }
      else if (value.name === 'show') {
        row.show = value.value;
      }

      this.shown_settings_data = [...this.shown_settings_data];

      this.local_item.fields = {};

      for (const r of this.shown_settings_data) {

        this.local_item.fields[r.name] = {
          show: r.show,
          required: r.required
        }
      }

      this.$emit('change', this.local_item);
    },

    find_node_with_row_number(row_number) {
      let row = 0;

      for (let i = 0; i < this.shown_settings_data.length; i++) {

        if (row === row_number) {
          return this.shown_settings_data[i];
        }

        row++;

      }

      return null;
    },



    validate() {
      this.force_validate = this.force_validate + 1;
    },

    update_form(field_name, value) {

      this.local_item[field_name] = value;

      this.local_item = { ...this.local_item };

      this.$emit('change', this.local_item);
    },

    validated(field_name, value, valid) {
      this.local_item[field_name] = value;

      if (!valid) {
        this.is_form_valid = false;
      }
    },

  },


  data() {
    return {

      shown_settings_data: [],

      local_item: {},

      force_validate: 0,
      is_form_valid: false,
      show_error: false,
      spinning: false,
      disabled: false,
      type: 'primary'
    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
